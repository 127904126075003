import TableCell from '@tiptap/extension-table-cell'
import {
  Plugin, PluginKey, EditorState, Transaction
} from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import styleAttribute from 'components/common/tiptap/extensions/utils/styleAttribute'

interface CellInfo {
  cellNode: ProseMirrorNode
  pos: number
}

const key = new PluginKey<CellInfo | null>('highlightSelectedTableCell')

const highlightSelectedTableCellPlugin = new Plugin({
  key,
  state: {
    init: () => null,

    apply: (transaction: Transaction): CellInfo | null => {
      // This function traverses up the node tree to find a 'tableCell' node
      // Returns the tableCell node and its start position if found, null otherwise
      const findTableCell = (selection: any) => {
        let depth = -1
        while (selection.$from.node(depth)) {
          const parent = selection.$from.node(depth)

          if (parent.type.name === 'tableCell' || parent.type.name === 'tableHeader') {
            return { cellNode: parent, pos: selection.$from.before(depth) }
          }

          depth -= 1
        }

        return null
      }

      return findTableCell(transaction.selection) || null
    },
  },

  props: {
    // Function to create decorations based on the plugin state
    decorations: (state: EditorState): DecorationSet | null => {
      const selectedCell = key.getState(state)
      if (!selectedCell) {
        return null
      }

      const { cellNode, pos } = selectedCell

      // Create a Decoration.node for the selected table cell
      const decoration = Decoration.node(pos, pos + cellNode.nodeSize, {
        class: 'selectedCell',
      })

      // Return a DecorationSet containing the decoration
      return DecorationSet.create(state.doc, [decoration])
    },
  },
})

export const clearyCellOverrides = {
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.backgroundColor) {
            return {}
          }

          return {
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
        parseHTML: element => element.style.backgroundColor.replace(/['"]+/g, ''),
      },
      verticalAlign: {
        default: 'middle',
        renderHTML: (attributes) => {
          if (!attributes.verticalAlign) {
            return {}
          }

          return {
            style: `vertical-align: ${attributes.verticalAlign}`,
          }
        },
        parseHTML: element => element.style.verticalAlign.replace(/['"]+/g, ''),
      },
      horizontalAlign: {
        default: 'left',
        renderHTML: (attributes) => {
          if (!attributes.horizontalAlign) {
            return {}
          }

          return {
            style: `text-align: ${attributes.horizontalAlign}`,
          }
        },
        parseHTML: element => element.style.textAlign.replace(/['"]+/g, ''),
      },
      thickBorder: {
        default: false,
        renderHTML: (attributes) => {
          if (!attributes.thickBorder) {
            return {}
          }

          return {
            class: 'thick-border',
          }
        },
        parseHTML: element => element.classList.contains('thick-border'),
      },
      highlightedBorder: {
        default: false,
        renderHTML: (attributes) => {
          if (!attributes.highlightedBorder) {
            return {}
          }

          return {
            class: 'highlighted-border',
          }
        },
        parseHTML: element => element.classList.contains('highlighted-border'),
      },
      width: styleAttribute('width'),
      outlineColor: styleAttribute('outlineColor'),
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      toggleTableCellThickBorder: () => ({ commands }) => {
        const isTableCell = this.editor.isActive('tableCell')
        if (isTableCell) {
          return commands.updateAttributes('tableCell', { thickBorder: !this.editor.getAttributes('tableCell').thickBorder })
        } else {
          return commands.updateAttributes('tableHeader', { thickBorder: !this.editor.getAttributes('tableHeader').thickBorder })
        }
      },
      toggleTableCellHighlightedBorder: () => ({ commands }) => {
        const isTableCell = this.editor.isActive('tableCell')
        if (isTableCell) {
          return commands.updateAttributes('tableCell', { highlightedBorder: !this.editor.getAttributes('tableCell').highlightedBorder })
        } else {
          return commands.updateAttributes('tableHeader', { highlightedBorder: !this.editor.getAttributes('tableHeader').highlightedBorder })
        }
      },
    }
  },
}

const ClearyTableCell = TableCell.extend({
  ...clearyCellOverrides,
  addProseMirrorPlugins() {
    const plugins = this.parent?.() || [] // Retrieve parent plugins

    plugins.push(
      highlightSelectedTableCellPlugin
    )

    return plugins
  },
})

export default ClearyTableCell
