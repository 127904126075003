import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Node as ProseMirrorNode, ResolvedPos } from 'prosemirror-model'
import { CellSelection, cellAround } from 'prosemirror-tables'
import { Extension } from '@tiptap/core'

interface CellInfo {
  cellNode: ProseMirrorNode
  pos: number
}

const key = new PluginKey<CellInfo | null>('clearyRightClick')

// Extracted from https://github.com/ProseMirror/prosemirror-tables/blob/master/src/input.ts#L303
function cellUnderMouse(
  view: EditorView,
  event: MouseEvent
): ResolvedPos | null {
  const mousePos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })

  if (!mousePos) return null

  return cellAround(view.state.doc.resolve(mousePos.pos))
}

const rightClickPlugin = setContextMenu => new Plugin({
  key,
  props: {
    handleDOMEvents: {
      mousedown: (view, event) => {
        const targetCell = cellUnderMouse(view, event)
        const selectionPositions: number[] = []

        if (
          !targetCell
          // Right click or Ctrl + left click
          || !(event.button === 2 || (event.button === 0 && event.ctrlKey))
        ) {
          setContextMenu(false)

          return
        }

        setContextMenu(true)

        if (!(view.state.selection instanceof CellSelection)) {
          return
        }

        view.state.selection.forEachCell((_node, pos) => selectionPositions.push(pos))

        if (selectionPositions.includes(targetCell.pos)) {
          event.preventDefault()
        }
      },
    },
  },
})

export default function createRightClickExtension(setContextMenu: (value: boolean) => void) {
  return Extension.create({
    name: 'clearyRightClick',

    addProseMirrorPlugins() {
      return [rightClickPlugin(setContextMenu)]
    },
  })
}
