const recalculateDimensions = (width: number, height: number, maxWidth: number, maxHeight: number) => {
  const ratio = Math.min(maxWidth / width, maxHeight / height)

  return {
    width: width * ratio,
    height: height * ratio,
  }
}

export default recalculateDimensions
