import { Plugin, PluginKey } from 'prosemirror-state'
import { Extension } from '@tiptap/core'

export const eventCaptureKey = new PluginKey('eventCapture')

const captureEventPlugin = new Plugin({
  key: eventCaptureKey,
  state: {
    init: () => null, // Initial state
    apply: (tr, prevState) => tr.getMeta(eventCaptureKey) || prevState,
  },
  props: {
    handleDOMEvents: {
      keydown: (view, event) => {
        view.dispatch(view.state.tr.setMeta(eventCaptureKey, event?.key))
        return false // Continue with other event handlers.
      },
      mousedown: (view, event) => {
        view.dispatch(view.state.tr.setMeta(eventCaptureKey, event?.type))
        return false
      },
    },
  },
})


const CaptureEventExtension = Extension.create({
  name: 'captureEvent',

  addProseMirrorPlugins() {
    return [
      captureEventPlugin,
    ]
  },
})

export default CaptureEventExtension
