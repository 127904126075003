import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Card from 'components/common/card'
import { I18NCommon } from 'utils/i18nHelpers'
import GiphyGif from 'components/common/gifs/gif'
import PoweredByGiphy from 'components/common/gifs/poweredByGiphy'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import ReactionsAndComments from 'components/common/reactions/reactionsAndComments'
import RichTextView from 'components/common/richTextView'
import Gif from 'components/common/tenor/gif'

interface Props {
  comment: any
  className?: string
  hideEditedTag?: boolean
  onToggleComments?: () => void
  showCommentsOnInit?: boolean
  useReactions?: boolean
  showReactionsAndComments?: boolean
  showUser?: boolean
  showRichText?: boolean
  cdnDomain?: string
  actionsComponent?: React.ReactNode
  nameOnTop?: boolean
}

const commentComponents = {
  giphy: ({ comment }) => (
    <div className='d-flex flex-column align-items-center mb-2 overflow-hidden'>
      <GiphyGif giphyId={comment.giphyId} className='mb-1' />

      <PoweredByGiphy className='align-self-end' />
    </div>
  ),
  gif: ({ comment }) => (
    <div className='d-flex flex-column align-items-center mb-2 overflow-hidden'>
      <Gif gifUrl={comment.gifUrl} className='mb-1' />
    </div>
  ),
  video: ({ comment }) => (
    <div className='mb-2'>
      <video src={comment.media.url} controls className='img-fluid' />
    </div>
  ),
  image: ({ comment }) => (
    <div className='mb-2'>
      <img src={comment.media.url} className='img-fluid comment-img-media' />
    </div>
  ),
  audio: ({ comment }) => (
    <div className='mb-2 px-4 pt-4'>
      <audio controls src={comment.media.url} className='w-100' />
    </div>
  ),
}

const Author = ({ user, size = '40px' }) => (
  <div className='d-flex align-items-center'>
    <EmployeeThumbnailPhoto employee={user} className='mr-2' linkToProfile size={size} />
    <Link to={`/profile/${user?.username}`} className='font-weight-600 text-primary'>{user?.preferredFullName}</Link>
  </div>
)

const StatelessCardComment = ({
  className,
  comment,
  hideEditedTag,
  onToggleComments,
  showCommentsOnInit,
  useReactions,
  showReactionsAndComments = true,
  showUser = true,
  showRichText = true,
  actionsComponent = null,
  nameOnTop = false,
}: Props) => {
  const { user, giphyId, gifUrl } = comment
  const MediaComponent = commentComponents[comment.media?.type] || (() => null)

  return (
    <Card
      className={classNames('GreetingCardComment mb-3 position-relative', className)}
      cardBodyClassName='p-0'
    >
      {showUser && nameOnTop && (
        <div className='p-2'>
          <Author user={user} size='25px' />
        </div>
      )}

      {actionsComponent}

      <MediaComponent comment={comment} />

      <div className={(giphyId || gifUrl) ? 'px-3 pb-3' : 'p-3'}>
        <div className='mb-2'>
          {showRichText ? (
            <>
              <RichTextView className='break-word' record={comment} recordType='comment' richTextKey='content' />
              {comment.editedAt && !hideEditedTag && (
                <div className='text-muted text-small'>({I18NCommon('edited')})</div>
              )}
            </>
          ) : (
            <div className='break-word' dangerouslySetInnerHTML={{ __html: comment.content }} />
          )}
        </div>
        {showUser && !nameOnTop && <Author user={user} />}
        {showReactionsAndComments && (
          <ReactionsAndComments
            record={comment}
            type='comment'
            onToggleComments={onToggleComments}
            showCommentsOnInit={showCommentsOnInit}
            onlyLike={!useReactions}
          />
        )}
      </div>
    </Card>
  )
}

export default StatelessCardComment
