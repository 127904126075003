import React, { ReactNode } from 'react'

import Tooltip from 'components/common/clearyTooltip'
import classNames from 'classnames'
import { OverlayTriggerProps } from 'react-bootstrap'
import CdnSvg from 'components/common/cdnSvg'

const infoIconPath = '/images/infoIcon.svg'

interface InfoTooltipProps {
  placement?: OverlayTriggerProps['placement'],
  tooltipClassName?: string,
  text: string | ReactNode,
  className?: string,
  textClassName?: string,
}

const InfoTooltip = ({
  placement = 'bottom',
  tooltipClassName = '',
  text = '',
  className = '',
  textClassName = '',
}: InfoTooltipProps) => (
  <Tooltip
    className={classNames('InfoTooltip', className)}
    placement={placement}
    tooltipClassName={tooltipClassName}
    hide={1000}
    content={(
      <span>
        <CdnSvg
          className='InfoIcon ml-1'
          src={infoIconPath}
        />
      </span>
    )}
  >
    <span className={classNames('ml-1', textClassName)}>{text}</span>
  </Tooltip>
)

export default InfoTooltip
