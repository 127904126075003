import { getMarkRange, Mark, mergeAttributes } from '@tiptap/react'
import { Plugin, TextSelection } from 'prosemirror-state'

export interface CommentOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment mark
       */
      setInlineComment: (dataInlineCommentId: string) => ReturnType,
      /**
       * Toggle a comment mark
       */
      toggleInlineComment: () => ReturnType,
      /**
       * Unset a comment mark
       */
      unsetInlineComment: () => ReturnType,
    }
  }
}

const inlineComment = Mark.create<CommentOptions>({
  name: 'inlineComment',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      dataInlineCommentId: {
        default: null,
        parseHTML: el => (el as HTMLSpanElement).getAttribute('data-inline-comment-id'),
        renderHTML: attrs => ({ 'data-inline-comment-id': attrs.dataInlineCommentId }),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-inline-comment-id]',
        getAttrs: dom => ({ dataInlineCommentId: (dom as HTMLSpanElement).getAttribute('data-inline-comment-id') }),
      },
    ]
  },

  addCommands() {
    return {
      setInlineComment: (dataInlineCommentId: string) => ({ commands }) => commands.setMark('inlineComment', { dataInlineCommentId }),
      toggleInlineComment: () => ({ commands }) => commands.toggleMark('inlineComment'),
      unsetInlineComment: () => ({ commands }) => commands.unsetMark('inlineComment'),
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const { schema, doc, tr } = view.state

            const range = getMarkRange(doc.resolve(pos), schema.marks.inlineComment)

            if (!range) return false

            const [$start, $end] = [doc.resolve(range.from), doc.resolve(range.to)]

            view.dispatch(tr.setSelection(new TextSelection($start, $end)))

            return true
          },
          handleTextInput(view, from, to, text) {
            const { state, dispatch } = view
            const { schema } = state
            const { tr } = state
            const range = getMarkRange(state.selection.$from, schema.marks.inlineComment)

            // If a character is inserted before or after a comment mark, remove the comment mark
            if (range && (to === range.to || from === range.from)) {
              tr.insertText(text)
              tr.removeMark(to, to + text.length, schema.marks.comment)
              dispatch(tr)
              return true
            }

            return false // Let ProseMirror handle it
          },
          // This is needed to prevent the newline added when pressing Enter from inheriting the comment mark
          handleKeyDown(view, event) {
            const { state, dispatch } = view
            const { schema } = state
            const { tr } = state
            const range = getMarkRange(state.selection.$from, schema.marks.inlineComment)

            if (event.key === 'Enter' && range) {
              const position = state.selection.$from.pos
              if (position === range.to) {
                tr.split(position)
                tr.removeMark(position + 1, position + 2, schema.marks.comment)
                dispatch(tr)
                return true
              }
            }

            return false // Let ProseMirror handle the event
          },
        },
      }),
    ]
  },
})


export default inlineComment
