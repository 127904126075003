import saveImageFromFile from 'components/common/tiptap/utils/saveImageFromFile'
import API from 'services/api'
import { RICH_TEXT_VALID_IMAGE_MIME_TYPES } from 'components/common/tiptap/extensions/clearyImage'
import { EMBED_VALID_FILE_MIME_TYPES } from 'components/common/tiptap/extensions/embed'
import saveAttachmentFromFile from 'components/common/tiptap/utils/saveAttachmentFromFile'
import uploadVideoFromFile from 'components/common/tiptap/utils/uploadVideoFromFile'
import processInsertedNodes from 'components/common/tiptap/handlers/processInsertedNodes'

// returning true will prevent the default drop behavior, and false will allow it
const handleTiptapEditorDrop = (view, event, slice, moved, richTextId, dispatch, config) => {
  const coords = { left: event.clientX, top: event.clientY }
  const dropPos = view.posAtCoords(coords).pos

  if (moved) return false

  processInsertedNodes(view, slice, dropPos, richTextId)

  return processDroppedFiles(view, event, dropPos, richTextId, dispatch, config)
}

const processDroppedFiles = (view, event, dropPos, richTextId, dispatch, config) => {
  const files = Array.from(event.dataTransfer?.files || [])

  if (_.isEmpty(files)) return false

  const { schema } = view.state

  Array.from(files).forEach((file: File) => {
    if (RICH_TEXT_VALID_IMAGE_MIME_TYPES.includes(file.type) && config.imagesEnabled) {
      const insertImage = (imageUrl: string, imageId: string) => {
        const node = schema.nodes.inlineImage.create({ 'src': imageUrl, 'data-image-id': imageId })
        const transaction = view.state.tr.insert(dropPos, node)
        view.dispatch(transaction)
      }

      saveImageFromFile(file, richTextId, insertImage)
    } else if (EMBED_VALID_FILE_MIME_TYPES.includes(file.type) && config.embedsEnabled) {
      const insertEmbed = (embedUrl: string, embedId: string) => {
        const node = schema.nodes.inlineEmbed.create({ 'src': embedUrl, 'data-file-id': embedId })
        const transaction = view.state.tr.insert(dropPos, node)
        view.dispatch(transaction)
      }

      saveAttachmentFromFile(file, richTextId, insertEmbed)
    } else if (file.type.includes('video/') && !!dispatch && config.videosEnabled) {
      const insertVideo = (videoId) => {
        const node = schema.nodes.inlineVideo.create({ 'data-video-id': videoId })
        const transaction = view.state.tr.insert(dropPos, node)
        view.dispatch(transaction)
      }

      uploadVideoFromFile(file, richTextId, dispatch, insertVideo)
    } else if (config.filesEnabled) {
      const insertLink = (linkUrl: string, linkId: string) => {
        const linkMark = schema.marks.link.create({ 'href': linkUrl, 'data-file-id': linkId, 'target': '_blank' })
        const node = schema.text(`${file.name}📎`, [linkMark])
        const transaction = view.state.tr.insert(dropPos, node)
        view.dispatch(transaction)
      }

      saveAttachmentFromFile(file, richTextId, insertLink)
    }
  })

  return true
}

export default handleTiptapEditorDrop
