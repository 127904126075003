import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import TrashIcon from 'components/icons/trashIcon'
import useClickOutside from 'components/common/hooks/useClickOutside'
import useWindowResize from 'components/common/hooks/useWindowResize'
import classNames from 'classnames'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { ButtonSmallNarrow } from 'components/common/buttons'
import CheckIcon from 'components/icons/checkIcon'

const COLORS = [
  { type: 'company', name: 'btnPrimaryColor' },
  { type: 'company', name: 'linkColor' },
  { type: 'company', name: 'highlightColor' },
  { type: 'company', name: 'subnavColor' },
  { type: 'company', name: 'bannerBackgroundColor' },
  { type: 'company', name: 'textColorPrimary' },
  { type: 'company', name: 'textColorSecondary' },
  { type: 'hex', value: '#FFFFFF' },
  { type: 'hex', value: '#000000' },
  { type: 'company', name: 'dangerColor' },
  { type: 'company', name: 'successColor' },
  { type: 'company', name: 'birthdayCardBackgroundColor' },
  { type: 'company', name: 'upcomingBirthdayColor' },
  { type: 'company', name: 'workAnniversaryBackgroundColor' },
  { type: 'company', name: 'upcomingWorkAnniversaryColor' },
  { type: 'company', name: 'newEmployeeWelcomeColor' },
  { type: 'company', name: 'upcomingNewEmployeeWelcomeColor' },
]

interface Props {
  isOpen: boolean
  onClose: () => void
  color: any
  onChange: (newColor: string) => void
  onRemove: () => void
  buttonRef: any
}

const HEX_REGEX = /^#[A-Fa-f0-9]{6}$/

export const transformColor = (color) => {
  if (!color) return '#'

  if (color.startsWith('#')) {
    return color
  }

  if (color.startsWith('rgb')) {
    const rgbValues = color.slice(color.indexOf('(') + 1, color.indexOf(')')).split(',')
    if (rgbValues && rgbValues.length === 3) {
      const hex = rgbValues.map(value => parseInt(value).toString(16).padStart(2, '0')).join('')
      return `#${hex}`
    }
  }

  return '#'
}

const BlockColorPicker = ({
  isOpen,
  onClose,
  color,
  onChange,
  onRemove,
  buttonRef,
}: Props) => {
  const colorPickerRef = useRef<any>()
  const [direction, setDirection] = useState<'bottom' | 'top'>('bottom')

  useClickOutside({
    ref: buttonRef,
    onClickOutside: onClose,
    enableClickOutside: isOpen,
  })

  const { height } = useWindowResize()

  useEffect(() => {
    if (isOpen && colorPickerRef.current && buttonRef.current) {
      const { y, height: buttonHeight } = buttonRef.current.getBoundingClientRect()
      const { height: colorPickerHeight } = colorPickerRef.current.getBoundingClientRect()

      if (y + buttonHeight + colorPickerHeight > height) {
        setDirection('top')
      } else {
        setDirection('bottom')
      }
    }
  }, [isOpen])

  const { customStyles: { variables } } = useCurrentCompany()

  const colors = _.uniq(COLORS.map(({ type, name, value }) => (type === 'company' ? variables.colors[name] : value)))

  if (!isOpen) {
    return null
  }

  return (
    <div
      ref={colorPickerRef}
      className={
        classNames('BlockColorPicker position-absolute zindex-dropdown mt-3', `BlockColorPicker--${direction}`)
      }
      onClick={e => e.stopPropagation()}
    >
      <SketchPicker
        color={color || '#FFFFFF'}
        onChangeComplete={(newColor) => {
          onChange(newColor.hex)
        }}
        presetColors={colors.slice(0, 10)}
        width={250}
      />
      <div className='InputContainer d-flex'>
        <ButtonSmallNarrow variant='link' onClick={onRemove} className='mr-2'>
          <TrashIcon className='TrashIcon mr-2' />
          <span>
            {I18n.t('common.reset')}
          </span>
        </ButtonSmallNarrow>
        <ButtonSmallNarrow variant='link' onClick={onClose}>
          <CheckIcon fillColor='var(--success-color)' className='CheckIcon mr-2' />
          <span>
            {I18n.t('common.apply')}
          </span>
        </ButtonSmallNarrow>
      </div>
    </div>
  )
}

export default BlockColorPicker
