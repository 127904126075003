import React, { useState, useEffect, useRef } from 'react'
import { Gif as GiphyGif } from '@giphy/react-components'
import classNames from 'classnames'

import giphyFetch from 'components/common/gifs/giphyFetch'
import recalculateDimensions from 'components/common/gifs/utils/recalculateDimensions'

const MAX_HEIGHT = 300

const Gif = ({ giphyId, className }) => {
  const [data, setData] = useState()
  const [gifDimensions, setGifDimensions] = useState({})
  const gifRef = useRef()

  useEffect(() => {
    if (giphyId) {
      giphyFetch.gif(giphyId).then(response => setData(response.data))
    }
  }, [giphyId])

  useEffect(() => {
    if (data?.images?.downsized) {
      // we need to explicitly set a height, otherwise the gifs jump around when you scroll through the page
      const { width, height } = data.images.downsized
      const maxWidth = gifRef.current.getBoundingClientRect().width

      setGifDimensions(recalculateDimensions(
        width,
        height,
        maxWidth,
        MAX_HEIGHT
      ))
    }
  }, [data])

  return (
    <div className={classNames('Gif w-100 d-flex justify-content-center', className)} ref={gifRef} id={giphyId}>
      {data && !_.isEmpty(gifDimensions) && (
        <GiphyGif
          gif={data}
          hideAttribution
          noLink
          height={gifDimensions.height}
          width={gifDimensions.width}
        />
      )}
    </div>
  )
}

export default Gif
