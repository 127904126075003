import React, { useEffect, useRef, useState } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import useClickOutside from 'components/common/hooks/useClickOutside'
import classNames from 'classnames'
import { TextSelection } from 'prosemirror-state'

const MIN_HEIGHT = 20
const MIN_WIDTH = 20

type Direction = 'top' | 'bottom' | 'left' | 'right' | 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight'

const getResizeOverlayClassName = (node) => {
  const { width, float } = node.attrs
  let className = ''
  if (typeof width === 'string' && width.includes('%')) {
    // we need to do this, because if the width is in % we need to stretch the overlay so the % width of the
    // image or iframe is respected.
    className = 'w-100'
  }
  if (float) {
    className += ` float-${float}`
  }
  return className
}

const ResizeOverlay = ({
  editor,
  node,
  updateAttributes,
  keepAspectRatioOnCornerResize = true,
  children,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const { textAlign } = node.attrs
  const wrapperRef = useRef<any>(null)

  useClickOutside({
    ref: wrapperRef,
    onClickOutside: () => setIsSelected(false),
    enableClickOutside: isSelected,
  })

  const generateResizeOnMouseDownHandler = (direction: Direction) => (mouseDownEvent: React.MouseEvent) => {
    mouseDownEvent.preventDefault()
    mouseDownEvent.stopPropagation()
    setIsDragging(true)

    const resizeCallback = (data) => {
      setIsDragging(false)
      updateAttributes(data)
    }

    resize(mouseDownEvent, direction, resizeCallback, keepAspectRatioOnCornerResize)
  }

  const generateMoveOnMouseDownHandler = () => (mouseDownEvent: React.MouseEvent) => {
    handleMoveOnMouseDown(mouseDownEvent, editor, node)
  }

  useEffect(() => {
    // Since we use ReactRenderer we need to set the text align on the parent,
    // otherwise textAlignment is not shown when editing.
    // There's no easy way to access the ReactRenderer
    if (textAlign) {
      const parentElement = wrapperRef.current?.parentNode
      parentElement.style.textAlign = textAlign
    }
  }, [textAlign])

  return (
    <NodeViewWrapper
      ref={wrapperRef}
      className={classNames('TiptapResizeOverlay', getResizeOverlayClassName(node))}
      onClick={() => setIsSelected(true)}
    >
      {children}
      <div className={classNames('resize-square', { isSelected, isDragging })} onMouseDown={generateMoveOnMouseDownHandler()}>
        {isSelected && (
          <>
            <div className='resize-handle resize-handle-top-left' onMouseDown={generateResizeOnMouseDownHandler('topLeft')} />
            <div className='resize-handle resize-handle-top' onMouseDown={generateResizeOnMouseDownHandler('top')} />
            <div className='resize-handle resize-handle-top-right' onMouseDown={generateResizeOnMouseDownHandler('topRight')} />
            <div className='resize-handle resize-handle-right' onMouseDown={generateResizeOnMouseDownHandler('right')} />
            <div className='resize-handle resize-handle-bottom-right' onMouseDown={generateResizeOnMouseDownHandler('bottomRight')} />
            <div className='resize-handle resize-handle-bottom' onMouseDown={generateResizeOnMouseDownHandler('bottom')} />
            <div className='resize-handle resize-handle-bottom-left' onMouseDown={generateResizeOnMouseDownHandler('bottomLeft')} />
            <div className='resize-handle resize-handle-left' onMouseDown={generateResizeOnMouseDownHandler('left')} />
          </>
        )}
      </div>
    </NodeViewWrapper>
  )
}

export default ResizeOverlay

const handleMoveOnMouseDown = (mouseDownEvent: React.MouseEvent, editor, node) => {
  const tiptapView = (mouseDownEvent.target as HTMLElement).closest('.TiptapEditor')
  if (tiptapView) {
    tiptapView.classList.add('caret-highlight-color')
  }

  const coords = { left: mouseDownEvent.clientX, top: mouseDownEvent.clientY }
  let startPos = editor.view.posAtCoords(coords).pos
  // when you click near the end of the image or iframe you get the position of the next node, we need to get the position of the current node
  while (!_.isEqual(editor.state.doc.nodeAt(startPos), node)) {
    startPos -= 1
  }

  const transaction = editor.state.tr.setSelection(TextSelection.create(editor.state.doc, startPos))
  editor.view.dispatch(transaction)

  const moveCursor = (mouseMoveEvent: MouseEvent) => {
    const coords = { left: mouseMoveEvent.clientX, top: mouseMoveEvent.clientY }
    const cursorPos = editor.view.posAtCoords(coords)?.pos

    if (cursorPos) {
      const transaction = editor.state.tr.setSelection(TextSelection.create(editor.state.doc, cursorPos))
      editor.view.dispatch(transaction)
    }
  }

  const onMouseUp = () => {
    if (tiptapView) {
      tiptapView.classList.remove('caret-highlight-color')
    }
    const { from: dropPos, empty } = editor.state.selection

    if (empty) {
      const transaction = editor.state.tr
      transaction.delete(startPos, startPos + node.nodeSize)

      // if the dropPos is greater than the startPos we need to decrease it by nodeSize
      // since everything after the startPos will be shifed by nodeSize
      transaction.insert(dropPos > startPos ? dropPos - node.nodeSize : dropPos, node)
      editor.view.dispatch(transaction)
    }

    document.body.removeEventListener('mousemove', moveCursor)
  }

  document.body.addEventListener('mousemove', moveCursor)
  document.body.addEventListener('mouseup', onMouseUp, { once: true })
}


const resize = (
  mouseDownEvent: React.MouseEvent,
  direction: Direction,
  callback: ({ height, width }) => void,
  keepAspectRatioOnCornerResize: boolean
) => {
  const parent = (mouseDownEvent.target as HTMLElement).closest('.TiptapResizeOverlay')
  const resizeSquare = parent?.querySelector('.resize-square') ?? null as any
  const tiptapView = parent?.closest('.TiptapView') as HTMLElement

  if (!resizeSquare || !tiptapView) return

  const originalWidth = resizeSquare.offsetWidth
  const originalHeight = resizeSquare.offsetHeight
  const aspectRatio = originalWidth / originalHeight
  const tiptapViewRect = tiptapView?.getBoundingClientRect()
  // we need to leave 2px for the cursor otherwise it will add an extra line
  const maxWidth = tiptapViewRect.width - 2

  const startX = mouseDownEvent.pageX
  const startY = mouseDownEvent.pageY

  const resizeFromBottom = (mouseMoveEvent: MouseEvent) => {
    const deltaY = startY - mouseMoveEvent.pageY
    resizeSquare.style.bottom = `${deltaY}px`
    resizeSquare.style.width = `${originalWidth}px`
    resizeSquare.style.height = `${Math.max(originalHeight - deltaY, MIN_HEIGHT)}px`
  }

  const resizeFromTop = (mouseMoveEvent: MouseEvent) => {
    const deltaY = mouseMoveEvent.pageY - startY
    resizeSquare.style.top = `${deltaY}px`
    resizeSquare.style.width = `${originalWidth}px`
    resizeSquare.style.height = `${Math.max(originalHeight - deltaY, MIN_HEIGHT)}px`
  }

  const resizeFromLeft = (mouseMoveEvent: MouseEvent) => {
    const deltaX = mouseMoveEvent.pageX - startX
    resizeSquare.style.left = `${deltaX}px`
    resizeSquare.style.width = `${Math.min(Math.max(originalWidth - deltaX, MIN_WIDTH), maxWidth)}px`
    resizeSquare.style.height = `${originalHeight}px`
  }

  const resizeFromRight = (mouseMoveEvent: MouseEvent) => {
    const deltaX = startX - mouseMoveEvent.pageX
    resizeSquare.style.right = `${deltaX}px`
    resizeSquare.style.width = `${Math.min(Math.max(originalWidth - deltaX, MIN_WIDTH), maxWidth)}px`
    resizeSquare.style.height = `${originalHeight}px`
  }

  const calculateNewDimensionsIgnoringAspectRatio = (deltaX, deltaY) => {
    const width = Math.min(Math.max(originalWidth - deltaX, MIN_WIDTH), maxWidth)
    const height = Math.max(originalHeight - deltaY, MIN_HEIGHT)

    return {
      x: `${deltaX}px`,
      y: `${deltaY}px`,
      width: `${width}px`,
      height: `${height}px`,
    }
  }

  // when resizing from corners we are going to keep aspect ratio and select largest image
  const calculateNewDimensionsKeepingAspectRatio = (deltaX, deltaY) => {
    let heightWhenChangingY = Math.max(originalHeight - deltaY, MIN_HEIGHT)
    let widthWhenChangingY = heightWhenChangingY * aspectRatio

    if (widthWhenChangingY > maxWidth) {
      widthWhenChangingY = maxWidth
      heightWhenChangingY = widthWhenChangingY / aspectRatio
    }

    const widthWhenChangingX = Math.min(Math.max(originalWidth - deltaX, MIN_WIDTH), maxWidth)
    const heightWhenChangingX = widthWhenChangingX / aspectRatio

    if (widthWhenChangingX > widthWhenChangingY) {
      return {
        x: `${deltaX}px`,
        y: `${originalHeight - heightWhenChangingX}px`,
        width: `${widthWhenChangingX}px`,
        height: `${heightWhenChangingX}px`,
      }
    } else {
      return {
        x: `${originalWidth - widthWhenChangingY}px`,
        y: `${deltaY}px`,
        width: `${widthWhenChangingY}px`,
        height: `${heightWhenChangingY}px`,
      }
    }
  }

  const calculateNewDimensions = keepAspectRatioOnCornerResize
    ? calculateNewDimensionsKeepingAspectRatio
    : calculateNewDimensionsIgnoringAspectRatio

  const resizeFromBottomLeft = (mouseMoveEvent: MouseEvent) => {
    const deltaX = mouseMoveEvent.pageX - startX
    const deltaY = startY - mouseMoveEvent.pageY
    const {
      x, y, width, height,
    } = calculateNewDimensions(deltaX, deltaY)
    resizeSquare.style.left = x
    resizeSquare.style.bottom = y
    resizeSquare.style.width = width
    resizeSquare.style.height = height
  }

  // when resizing from corners we are going to keep aspect ratio and select largest image
  const resizeFromBottomRight = (mouseMoveEvent: MouseEvent) => {
    const deltaX = startX - mouseMoveEvent.pageX
    const deltaY = startY - mouseMoveEvent.pageY
    const {
      x, y, width, height,
    } = calculateNewDimensions(deltaX, deltaY)
    resizeSquare.style.right = x
    resizeSquare.style.bottom = y
    resizeSquare.style.width = width
    resizeSquare.style.height = height
  }

  const resizeFromTopLeft = (mouseMoveEvent: MouseEvent) => {
    const deltaX = mouseMoveEvent.pageX - startX
    const deltaY = mouseMoveEvent.pageY - startY
    const {
      x, y, width, height,
    } = calculateNewDimensions(deltaX, deltaY)
    resizeSquare.style.left = x
    resizeSquare.style.top = y
    resizeSquare.style.width = width
    resizeSquare.style.height = height
  }

  const resizeFromTopRight = (mouseMoveEvent: MouseEvent) => {
    const deltaX = startX - mouseMoveEvent.pageX
    const deltaY = mouseMoveEvent.pageY - startY
    const {
      x, y, width, height,
    } = calculateNewDimensions(deltaX, deltaY)
    resizeSquare.style.right = x
    resizeSquare.style.top = y
    resizeSquare.style.width = width
    resizeSquare.style.height = height
  }

  const getResizeFromDirection = () => {
    switch (direction) {
    case 'bottom':
      return resizeFromBottom
    case 'top':
      return resizeFromTop
    case 'left':
      return resizeFromLeft
    case 'right':
      return resizeFromRight
    case 'bottomLeft':
      return resizeFromBottomLeft
    case 'bottomRight':
      return resizeFromBottomRight
    case 'topLeft':
      return resizeFromTopLeft
    case 'topRight':
      return resizeFromTopRight
    default:
      return () => {}
    }
  }

  const resizeFromDirection = getResizeFromDirection()

  const onMouseUp = () => {
    callback({
      height: `${resizeSquare.offsetHeight}px`,
      width: `${resizeSquare.offsetWidth}px`,
    })
    resizeSquare.style = null
    document.body.removeEventListener('mousemove', resizeFromDirection)
  }

  document.body.addEventListener('mousemove', resizeFromDirection)
  document.body.addEventListener('mouseup', onMouseUp, { once: true })
}
