import { useEffect, useState } from 'react'
import { createConsumer } from '@rails/actioncable'
import snakeCaseKeys from 'utils/snakeCaseKeys'

let sharedCable = null

// This is to create a shared cable connection for all components
// And also avoid connecting to the cable server if we don't need to (i.e. no active subscriptions)
const getSharedCable = () => {
  if (!sharedCable) {
    sharedCable = createConsumer('/cable')
  }

  return sharedCable
}

const useActionCable = ({
  channel,
  recordType,
  recordId,
  isActive = true,
  onDataReceived = (data) => {},
  onConnected = (subscription) => {},
  onDisconnected = (subscription) => {},
}) => {
  const [subscription, setSubscription] = useState<any>(null)

  useEffect(() => {
    let newSubscription
    let cable

    if (isActive) {
      cable = getSharedCable()
      newSubscription = cable.subscriptions.create(
        snakeCaseKeys({
          channel,
          recordType,
          id: recordId,
        }),
        {
          received: onDataReceived,
          connected() {
            onConnected(newSubscription)
          },
          disconnected() {
            onDisconnected(newSubscription)
          },
        }
      )
      setSubscription(newSubscription)
    }

    return () => {
      newSubscription?.unsubscribe()
      setSubscription(null)

      if (_.isEmpty(cable?.subscriptions?.subscriptions)) {
        cable?.disconnect()
      }
    }
  }, [isActive, recordId, recordType, channel])

  return subscription
}

export default useActionCable
