import Axios from 'axios'
import { PageFAQType } from 'types/page/faq'

export const buildPagesFaqPayload = (faq: PageFAQType) => _.pick(faq, ['id', 'question', 'answer'])

const pagesFAQs = {
  fetchAll(pageId: string) {
    return Axios.get(`/api/pages/${pageId}/faqs`)
  },

  fetch(pageId: string, faqId: string) {
    return Axios.get(`/api/pages/${pageId}/faqs/${faqId}`)
  },

  update(pageId: string, faq: PageFAQType) {
    return Axios.patch(`/api/pages/${pageId}/faqs/${faq.id}`, { faq: buildPagesFaqPayload(faq) })
  },

  create(pageId: string, faq: PageFAQType) {
    return Axios.post(`/api/pages/${pageId}/faqs`, { faq: buildPagesFaqPayload(faq) })
  },

  destroy(pageId: string, faqId: string) {
    return Axios.delete(`/api/pages/${pageId}/faqs/${faqId}`)
  },
}

export default pagesFAQs
