import React, { useEffect } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import RichTextView from 'components/common/richTextView'
import { Button } from 'components/common/buttons'
import useAiTextGenerationEvents from 'components/common/tiptap/hooks/ai/useAiTextGenerationEvents'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import InfoTooltip from 'components/common/infoTooltip'
import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model'
import BetaTag from 'components/common/tags/betaTag'

const I18N = i18nPath('views.tiptap.ai')

const ErrorMessage = ({ errorReason }) => (
  <span className='ErrorMessage text-danger text-small'>
    {I18N(`error_generating_content.${errorReason}`)}
    <InfoTooltip
      text={I18N(`error_generating_content_tooltip.${errorReason}`)}
      tooltipClassName='error-tooltip'
    />
  </span>
)

const AiTextGenerationComponent = ({
  editor,
  node,
  getPos,
}) => {
  const prompt = node.attrs.prompt || ''
  const textGenerationId = node.attrs['data-text-generation-id']

  const {
    result,
    status,
    errorReason,
    isLoading,
  } = useAiTextGenerationEvents(textGenerationId)

  const isErrored = status === 'generation_error'

  const insertContent = () => {
    const nodePos = getPos()
    const nodeSize = node.nodeSize

    // Since the prompt might be html, we need to escape it
    const escapedPrompt = prompt.replace(/"/g, '&quot;')
    const parser = new window.DOMParser()
    const resultWithWrapper = `<div class="ai-block" prompt="${escapedPrompt}">${result}</div>`
    const doc = parser.parseFromString(resultWithWrapper, 'text/html')
    // We need to parse the html to a ProseMirror document to avoid any errors inserting the content
    const content = ProseMirrorDOMParser.fromSchema(editor.state.schema).parse(doc.body)
    const transaction = editor.state.tr
      .deleteRange(nodePos, nodePos + nodeSize)
      .insert(nodePos, content)

    editor.view.dispatch(transaction)
  }

  const onStop = () => {
    const nodePos = getPos()
    const nodeSize = node.nodeSize
    const newNode = editor.schema.nodes.aiPrompt.create({ prompt })
    const transaction = editor.state.tr.delete(nodePos, nodePos + nodeSize).insert(nodePos, newNode)
    editor.view.dispatch(transaction)
  }

  useEffect(() => {
    if (status === 'completed' && result) {
      insertContent()
    }
  }, [status])

  return (
    <NodeViewWrapper className={classNames('AiTextGenerationComponent w-100', { isErrored })}>
      <div className='p-3'>
        <RichTextView
          className='content-rich-view'
          recordType='text'
          richTextKey='content'
          record={{ content: result }}
        />
        {!isLoading && (
          <div className='mt-2 w-100 d-flex align-items-center justify-content-between'>
            {isErrored ? (
              <>
                <ErrorMessage errorReason={errorReason} />
                {/* We call onStop to go back to the prompt when you want to retry */}
                <Button variant='secondary' onClick={onStop}>
                  {I18N('retry')}
                </Button>
              </>
            ) : (
              <>
                <span className='text-secondary text-small'>
                  {I18N('cleary_ai_is_thinking')}
                  <div className='dot-typing ml-3' />
                </span>
                <div className='d-flex align-items-center'>
                  <Button variant='secondary' onClick={onStop}>
                    {I18N('stop')}
                  </Button>
                  <BetaTag className='ml-2' />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </NodeViewWrapper>
  )
}

export default AiTextGenerationComponent
