import React from 'react'

const ArrowheadLeftIcon = ({
  height = '100%', width = '100%', className, fillColor = 'var(--subnav-text-color)', rotate = 0,
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    fill={fillColor}
    viewBox='0 0 24 24'
    style={rotate !== 0 ? { transform: `rotate(${rotate}deg)` } : {}}
  >
    <path d='M14.29 12l4.48-5.37a1 1 0 00-1.54-1.28l-5 6a1 1 0 000 1.27l4.83 6a1 1 0 00.78.37 1 1 0 00.78-1.63z' />
  </svg>
)

export default ArrowheadLeftIcon
