import {
  createContext, useContext, useEffect, useRef, useState
} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTapAndHold } from 'components/common/tapAndHold'

const INTERVAL_TIME = 10

export const SlideShowContext = createContext<any>(null)

export const useSlideShow = () => useContext(SlideShowContext)

export enum SlideControlType {
  History = 'history',
  State = 'state',
}

const DEFAULT_SLIDE_DISPLAY_TIME = 3000


interface Props {
  celebration: any
  defaultSlideDisplayTime?: number
  comments?: any[]
  slideControlType?: SlideControlType
  onExitSlideShow?: () => void
}

const useCreateSlideShow = ({
  celebration,
  defaultSlideDisplayTime = DEFAULT_SLIDE_DISPLAY_TIME,
  comments = [],
  slideControlType = SlideControlType.History,
  onExitSlideShow,
}: Props) => {
  const history = useHistory()
  const autoPlayInterval = useRef<any>(null)
  const [autoPlay, setAutoPlay] = useState(false)
  const [slideNumberState, setSlideNumber] = useState(1)
  const slideNumberQueryParams = parseInt(useParams().slideNumber)
  const slideNumber = slideControlType === SlideControlType.History ? slideNumberQueryParams : slideNumberState

  const [finished, setFinished] = useState(false)
  const [autoPlayTime, setAutoPlayTime] = useState(0)
  const numberOfSlides = celebration?.commentsCount + 1
  const currentSlide = !slideNumber || slideNumber > numberOfSlides ? 1 : Math.max(slideNumber, 1)
  const [slideClass, setSlideClass] = useState(currentSlide === 1 ? 'slide-next' : 'slide-previous')
  const [slideDisplayTime, setSlideDisplayTime] = useState(defaultSlideDisplayTime)

  const updateSlideNumber = (newSlideNumber) => {
    if (slideControlType === SlideControlType.History) {
      history.push(`/celebrations/${celebration.id}/slide_show/${newSlideNumber}`)
    } else {
      setSlideNumber(newSlideNumber)
    }
  }

  const prev = () => {
    setFinished(false)
    setSlideClass('slide-previous')
    setAutoPlayTime(0)
    updateSlideNumber(currentSlide - 1)
  }

  const next = () => {
    setFinished(false)
    setSlideClass('slide-next')
    setAutoPlayTime(0)
    updateSlideNumber(currentSlide + 1)
  }

  const goTo = (slide) => {
    setFinished(false)
    setAutoPlayTime(0)
    updateSlideNumber(slide)
  }

  const start = () => {
    setAutoPlayTime(0)
    setAutoPlay(true)
    setFinished(false)
    updateSlideNumber(1)
  }

  const resume = () => {
    if (finished) return
    setFinished(false)
    setAutoPlay(true)
  }

  const pause = () => {
    if (finished) return
    setAutoPlay(false)
  }

  const toggle = () => {
    setFinished(false)
    setAutoPlay(value => !value)
  }

  const hasAnyParentClass = (element, classNames) => {
    if (!element) return false

    // eslint-disable-next-line no-restricted-syntax
    for (const className of classNames) {
      if (element.classList?.contains(className)) {
        return true
      }
    }

    return hasAnyParentClass(element.parentElement, classNames)
  }

  const simpleTap = (e) => {
    const { width } = e.target.getBoundingClientRect()

    if (
      e.target.tagName === 'VIDEO'
      || e.target.tagName === 'AUDIO'
      || hasAnyParentClass(e.target, ['ReactionsAndComments', 'Offcanvas'])
    ) {
      stop()

      return
    }

    let element = e.target
    while (element) {
      if (element.classList?.contains('modal-content')) {
        return
      }

      element = element.parentElement
    }

    if (e.changedTouches && e.changedTouches.length > 0) {
      const [{ clientX }] = e.changedTouches

      setAutoPlayTime(0)

      if (clientX < width / 2) {
        prev()
      } else {
        next()
      }
    }
  }

  const tapAndHold = useTapAndHold(pause, resume, simpleTap)

  useEffect(() => {
    if (!comments[currentSlide - 2]) {
      setSlideDisplayTime(defaultSlideDisplayTime)

      return
    }

    /**
     * We get the current comment by decrement the current slide by 2 because the currentSlide is 1-based
     * and the first slide is the cover slide, so we need to decrement it by 2 to get the first comment
     */
    const comment = comments[currentSlide - 2]

    if (comment.media.type === 'giphy' || comment.media.type === 'text') {
      const numberOfWords = comment.content.split(' ').length
      const numberOfSeconds = Math.max(Math.round((numberOfWords / 10)) * 3, 3)

      setSlideDisplayTime(numberOfSeconds * 1000)
    } else {
      const media: HTMLMediaElement | null = document.querySelector(`#comment-${comment.id} video`)
        || document.querySelector(`#comment-${comment.id} audio`)

      if (!media) return

      media.addEventListener('durationchange', (e: any) => {
        if (!e.target.duration) return

        setSlideDisplayTime(e.target.duration * 1000)
      })

      media.play()
    }
  }, [currentSlide, JSON.stringify(comments.map(comment => comment.id))])

  useEffect(() => {
    if (autoPlay) {
      autoPlayInterval.current = setInterval(() => {
        setAutoPlayTime((currentTime) => {
          if (currentTime > slideDisplayTime) {
            if (currentSlide === numberOfSlides) {
              clearInterval(autoPlayInterval.current)
              stop()

              setFinished(true)

              return slideDisplayTime
            } else {
              next()
            }

            return 0
          }

          return currentTime + INTERVAL_TIME
        })
      }, INTERVAL_TIME)
    } else {
      clearInterval(autoPlayInterval.current)
    }

    return () => {
      clearInterval(autoPlayInterval.current)
    }
  }, [autoPlay, celebration?.id, currentSlide, slideDisplayTime])

  return {
    celebration,
    currentSlide,
    numberOfSlides,
    prev,
    next,
    goTo,
    start,
    resume,
    pause,
    toggle,
    finished,
    slideClass,
    autoPlay,
    autoPlayTime,
    slideDisplayTime,
    tapAndHold,
    comments,
    onExitSlideShow,
  }
}

export default useCreateSlideShow
