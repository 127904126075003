import Mention from '@tiptap/extension-mention'
import { Suggestion } from 'components/common/tiptap/extensions/suggestion'


const ClearyMention = Mention.extend({
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

export default ClearyMention
