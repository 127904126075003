import React from 'react'

import classNames from 'classnames'
import DoubleArrowheadLeftIcon from 'components/icons/doubleArrowheadLeftIcon'
import ArrowheadLeftIcon from 'components/icons/arrowheadLeftIcon'
import { i18nPath } from 'utils/i18nHelpers'

const PaginationContainer = ({
  page,
  numPages,
  perPage,
  totalCount,
  handleClick,
  numbered = true,
  entriesTextClassName = undefined,
  i18nPrefix = 'views.common.pagination_container',
}) => {
  const I18N = i18nPath(i18nPrefix)

  const onPageClick = (page) => {
    if (Number.isInteger(page)) {
      handleClick(page)
    }
  }

  const paginationArray = () => {
    // always show the first and last page
    let pageList = [1, numPages]
    // and show one trailing and two leading numPages
    pageList.push(page - 1, page, page + 1, page + 2)

    // now remove those numPages that are > max numPages
    pageList = pageList.filter(page => page <= numPages && page > 0)

    // filter out duplicates
    pageList = [...new Set(pageList)]

    pageList = pageList.sort((a, b) => a - b)

    // add omission markers
    let index = 1
    while (index < pageList.length) {
      if (pageList[index - 1] < pageList[index] - 1) {
        pageList.splice(index, 0, '…')
        index += 2
      } else {
        index += 1
      }
    }

    return pageList
  }


  const onArrowClick = (direction = 'left') => {
    if (direction === 'left') {
      if (page > 1) {
        handleClick(page - 1)
      }
    } else {
      if (page < numPages) {
        handleClick(page + 1)
      }
    }
  }

  const entriesText = () => {
    if (totalCount === 0) return ''
    const endOfRange = Math.min(page * perPage, totalCount)
    return I18N('pagination_entries_text', { start: (page - 1) * perPage + 1, end: endOfRange, total: totalCount })
  }

  const array = paginationArray()

  if (numPages <= 1) return null

  return (
    <div className='PaginationContainer d-flex mt-3 flex-wrap'>
      <p className={classNames('entriesText', entriesTextClassName)}>{entriesText()}</p>
      <ol className='pagination d-flex justify-content-center align-items-flex-start'>
        {numPages > 5 && (
          <span onClick={() => handleClick(1)}>
            <DoubleArrowheadLeftIcon
              className={classNames({
                'pointer arrow left double': true,
                'disabled': page === 1,
              })}
              fillColor={page === 1 ? 'var(--text-color-secondary)' : 'var(--text-color-primary)'}
            />
          </span>
        )}
        <span onClick={() => onArrowClick()} data-cy='left-arrow'>
          <ArrowheadLeftIcon
            className={classNames({
              'pointer arrow left': true,
              'disabled': page === 1,
            })}
            fillColor={page === 1 ? 'var(--text-color-secondary)' : 'var(--text-color-primary)'}
          />
        </span>
        {array.map((item, index) => {
          const element = isNaN(item) ? item : <a>{item}</a>
          return (
            <li
              className={classNames({
                'active': item === page,
                'ml-0': true,
                'mr-3': index !== array.length - 1,
              })}
              key={index}
              onClick={e => onPageClick(item, e)}
              data-cy={`page-number-${item === page ? 'active' : `inactive-${item}`}`}
            >
              {numbered && element}
            </li>
          )
        })}
        <span onClick={() => onArrowClick('right')} data-cy='right-arrow'>
          <ArrowheadLeftIcon
            className={classNames({
              'pointer arrow right': true,
              'disabled': page === numPages,
            })}
            rotate={180}
            fillColor={page === numPages ? 'var(--text-color-secondary)' : 'var(--text-color-primary)'}
          />
        </span>
        {numPages > 5 && (
          <span onClick={() => handleClick(numPages)}>
            <DoubleArrowheadLeftIcon
              className={classNames({
                'pointer arrow right double': true,
                'disabled': page === numPages,
              })}
              rotate={180}
              fillColor={page === numPages ? 'var(--text-color-secondary)' : 'var(--text-color-primary)'}
            />
          </span>
        )}
      </ol>
    </div>
  )
}

export default PaginationContainer
