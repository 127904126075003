import React, {
  useCallback, useEffect, useRef, useState
} from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import KebabMenu from 'components/common/kebabMenu'
import BetaTag from 'components/common/tags/betaTag'
import useClickOutside from 'components/common/hooks/useClickOutside'

const NavLinkItem = ({ navLink, onClick }) => {
  const commonProps = {
    className: 'Navbar-dropdownMenuItem px-3 py-2 d-flex align-items-center justify-content-between',
    onClick,
  }
  const children = (
    <>
      <span>{navLink.title}</span>
      {navLink.beta && <BetaTag className='ml-3 small' />}
    </>
  )

  return navLink.externalUrl ? (
    <a {...commonProps} href={navLink.linkTo} target='_blank' rel='noopener noreferrer'>{children}</a>
  ) : (
    <NavLink {...commonProps} to={navLink.linkTo}>{children}</NavLink>
  )
}

interface DropdownMenuContainerProps {
  dropdownOffsetPx?: number
  dropdownHorizontalOffsetPx?: number
  className?: string
  iconWidth?: string
  iconHeight?: string
  toggleWrapperClassName?: string
  id?: string
  style?: any
  toggleContent?: string | React.ReactNode
  lightColorMenu?: boolean
  navLinks?: {
    linkTo: string
    onClick: () => void
    title: string
    beta: boolean
  }[]
  children?: any
  closeMenuOnItemClick?: boolean
  menuType?: string
}

const DropdownMenuContainer = ({
  dropdownOffsetPx = 8,
  dropdownHorizontalOffsetPx = -1,
  className,
  toggleWrapperClassName = 'position-relative',
  id,
  iconWidth,
  iconHeight,
  style,
  toggleContent,
  lightColorMenu,
  navLinks,
  children,
  closeMenuOnItemClick = false,
  menuType = '', // 'kebab' will produce a kebab menu, the default is a meatball menu
}: DropdownMenuContainerProps) => {
  const dropdownMenuRef = useRef<any>(null)
  const dropdownToggleRef = useRef<any>(null)
  const dropdownContentRef = useRef<any>(null)
  const [showMenuDropdown, setShowMenuDropdown] = useState(false)

  useClickOutside({
    ref: dropdownMenuRef,
    onClickOutside: () => setShowMenuDropdown(false),
    enableClickOutside: showMenuDropdown,
  })

  const handleItemClick = useCallback((e) => {
    const currentDropdownContentRef = dropdownContentRef.current
    // hide menu when clicking inside the menu
    if (currentDropdownContentRef && currentDropdownContentRef.contains(e.target)) {
      // eslint-disable-next-line no-use-before-define
      closeDropdown()
    }
  }, [])

  const closeDropdown = () => setShowMenuDropdown(false)

  useEffect(() => {
    if (closeMenuOnItemClick) {
      document.addEventListener('click', handleItemClick)
    }

    // cleanup
    return () => {
      document.removeEventListener('click', handleItemClick)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const toggleHeight = dropdownToggleRef.current?.getBoundingClientRect().height || 0
  const dropdownOffsetHeight = `${toggleHeight + dropdownOffsetPx}px`

  return (
    <div id={id} className={classNames('DropdownMenu-container', className)} style={style}>
      <div className={toggleWrapperClassName} ref={dropdownMenuRef}>
        <div className='DropdownMenu-toggle'
          onClick={() => setShowMenuDropdown(!showMenuDropdown)}
          ref={dropdownToggleRef}
        >
          {
            toggleContent || (
              <KebabMenu
                lightColor={lightColorMenu}
                className={menuType}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
              />
            )
          }
        </div>

        {showMenuDropdown && (
          <div
            className='DropdownMenu-content'
            ref={dropdownContentRef}
            style={{ top: dropdownOffsetHeight, right: `${dropdownHorizontalOffsetPx}px` }}
          >
            {navLinks
              ? navLinks.map((n, i) => <NavLinkItem key={i} navLink={n} onClick={n.onClick || closeDropdown} />)
              : children}
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownMenuContainer
