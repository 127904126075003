import React from 'react'
import classNames from 'classnames'
import getCdnUrl from 'utils/getCdnUrl'
import { useSelector } from 'react-redux'

const PoweredByGiphy = ({ className = '' }) => {
  const cdnDomain = useSelector(state => state.cdnDomain)

  return (
    <img
      className={classNames('PoweredByGiphy', className)}
      src={getCdnUrl(cdnDomain, '/images/poweredByGiphy.png')}
      alt='giphy'
    />
  )
}

export default PoweredByGiphy
