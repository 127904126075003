import classNames from 'classnames'
import useFetch from 'components/common/hooks/useFetch'
import RichTextInlineComment from 'components/common/tiptap/richTextInlineComments/richTextInlineComment'
import DoubleArrowheadLeftIcon from 'components/icons/doubleArrowheadLeftIcon'
import React, { useState } from 'react'
import API from 'services/api'

const RichTextInlineComments = ({ richTextId }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { data } = useFetch(API.richTextInlineComments.fetchAll, [richTextId])

  return (
    <div className={classNames('RichTextInlineComments p-3', { isExpanded })}>
      <button
        className='ToggleButton'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <DoubleArrowheadLeftIcon className={classNames('flex-shrink-0', { isExpanded })} />
      </button>
      {data?.map(richTextInlineComment => (
        <RichTextInlineComment
          key={richTextInlineComment.id}
          richTextInlineComment={richTextInlineComment}
          isExpanded={isExpanded}
        />
      ))}
    </div>
  )
}

export default RichTextInlineComments
