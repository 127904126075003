import generateCrudRoutes from 'services/generateCrudRoutes'

export const buildLogoPayload = logo => _.pick(logo, [
  'id',
  'title',
  'text',
  'color',
  'subnavColor',
  'link',
  'image',
  'startTime',
  'endTime',
  'width',
  'height',
])

export default generateCrudRoutes('/api/admin/logos', 'logo')
