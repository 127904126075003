import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'

const buildShoutoutPayload = shoutout => _.pick(shoutout, [
  'id',
  'text',
  'shoutoutSuggestionId',
  'companyValueIds',
  'userIds',
  'gifUrl',
  'giphyId',
])

const shoutouts = _.merge(generateCrudRoutes('/api/shoutouts', 'shoutout', {}, buildShoutoutPayload), {
  fetchSuggestions() {
    return Axios.get('/api/shoutout_suggestions')
  },

  createDemoSuggestions() {
    return Axios.post('/api/shoutout_suggestions/create_demo_suggestions')
  },

  clearSuggestions() {
    return Axios.post('/api/shoutout_suggestions/clear')
  },
})

export default shoutouts
