import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import GifPicker, { ContentFilter, GifPickerProps } from 'gif-picker-react'
import { createPortal } from 'react-dom'
import CdnSvg from 'components/common/cdnSvg'
import pageVariables from 'utils/pageVariables'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

interface Props extends Omit<GifPickerProps, 'tenorApiKey'> {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

const GifSearcher = ({
  searchTerm, setSearchTerm, ...props
}: Props) => {
  const [showTrending, setShowTrending] = useState(false)
  const { locale } = useCurrentUser()

  const pickerContextValue = useMemo(() => ({
    searchTerm,
    showTrending,
  }), [searchTerm, showTrending])

  const setPickerContextValue = (value: any) => {
    setSearchTerm(value.searchTerm)
    setShowTrending(value.showTrending)
  }

  const [poweredByTenorContainer, setPoweredByTenorContainer] = useState<HTMLDivElement | null>(null)

  const wrapperRef = useRef<HTMLDivElement>(null)

  // The 'gif-picker-react' does not have great support in terms of customization.
  // So we need to manually add the 'Powered by Tenor' logo to the gif picker.
  useEffect(() => {
    const containerClass = showTrending ? '.gpr-title-container' : '.gpr-search-container'
    const containerDiv = wrapperRef.current!.querySelector(containerClass) as HTMLDivElement
    const poweredByTenorContainer = document.createElement('div')
    containerDiv.insertAdjacentElement('afterend', poweredByTenorContainer)
    setPoweredByTenorContainer(poweredByTenorContainer)

    return () => {
      poweredByTenorContainer.remove()
    }
  }, [showTrending])

  return (
    <div ref={wrapperRef}>
      {poweredByTenorContainer && createPortal(
        <CdnSvg src='/images/logos/poweredByTenor.svg' className='d-block mb-2 ml-auto PoweredByTenorSVG' />,
        poweredByTenorContainer
      )}
      <GifPicker
        {...props}
        tenorApiKey={pageVariables.tenorApiKey}
        pickerContext={[pickerContextValue, setPickerContextValue]}
        locale={locale}
        contentFilter={ContentFilter.HIGH}
      />
    </div>
  )
}

export default GifSearcher
