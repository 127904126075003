import { EditorView } from 'prosemirror-view'
import { Node } from 'prosemirror-model'
import API from 'services/api'
import appSignal from 'services/appSignal'


const processInsertedImage = async (view: EditorView, node: Node, nodePos: number, richTextId: string) => {
  try {
    const response = await API.temporaryImageUpload.create(node.attrs.src, richTextId)
    const { imageUrl, imageId } = response.data

    const transaction = view.state.tr.setNodeAttribute(nodePos, 'src', imageUrl).setNodeAttribute(nodePos, 'data-image-id', imageId)
    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processInsertedVideo = async (view, node, nodePos, richTextId) => {
  try {
    let response
    const dataVideoId = node.attrs['data-video-id']

    if (!dataVideoId && node.attrs.src) {
      // When it's a video from an external source, we need to create a new video record
      // This way we can track the video playback events using our player

      const src = node.attrs.src

      response = await API.videos.create({
        richTextId,
        name: src.split('/').pop(),
        externalUrl: src,
        encodedState: 'finished',
      })
      const transaction = view.state.tr.setNodeAttribute(nodePos, 'data-video-id', response.data.data.id)
      view.dispatch(transaction)
    }
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processPastedClearyEmbed = async (view, node, nodePos, richTextId) => {
  try {
    const response = await API.attachments.duplicate(node.attrs['data-file-id'], richTextId)
    const { fileUrl, fileId } = response.data

    const transaction = view.state.tr.setNodeAttribute(nodePos, 'src', fileUrl).setNodeAttribute(nodePos, 'data-file-id', fileId)
    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processPastedClearyLink = async (view, node, nodePos, richTextId, mark) => {
  try {
    const response = await API.attachments.duplicate(mark.attrs['data-file-id'], richTextId)
    const { fileUrl, fileId } = response.data

    const transaction = view.state.tr.removeMark(
      nodePos,
      nodePos + node.nodeSize,
      view.state.schema.marks.link
    ).addMark(
      nodePos,
      nodePos + node.nodeSize,
      view.state.schema.marks.link.create({ ...mark.attrs, 'href': fileUrl, 'data-file-id': fileId })
    )

    view.dispatch(transaction)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
  }
}

const processInsertedNodes = (view: EditorView, slice, startPos, richTextId) => {
  let isFirstElementAParagraph = false

  slice.content.descendants((node, pos) => {
    if (node.type.name === 'paragraph' && pos === 0) {
      isFirstElementAParagraph = true
    }
  })

  slice.content.descendants(async (node, pos) => {
    // we need to get the position of the content when it's pasted
    // when pasting a single image pos is 0, so we just use the startPos
    // when pos is present, we need to add the pos within the slice and the startPos of the actual content and subtract 1 because both start at 1
    let nodePos = pos + startPos - 1

    // User can copy paste only the element or can select a range that will bring the element inside a paragraph
    // if copies only the element, it'll be placed inside a paragraph, so we need to add 1 to the nodePos in order to skip the paragraph
    if (!isFirstElementAParagraph) {
      nodePos += 1
    }

    if (node.type.name === 'inlineImage' || node.type.name === 'blockImage') {
      await processInsertedImage(view, node, nodePos, richTextId)
    } else if (node.type.name === 'inlineVideo' || node.type.name === 'blockVideo') {
      await processInsertedVideo(view, node, nodePos, richTextId)
    } else if (node.type.name === 'inlineEmbed' || node.type.name === 'blockEmbed') {
      const fileId = node.attrs['data-file-id']
      if (fileId) {
        await processPastedClearyEmbed(view, node, nodePos, richTextId)
      }
    } else {
      const clearyLinkMark = node.marks?.find((mark: any) => mark.type.name === 'link' && mark.attrs['data-file-id'])
      if (clearyLinkMark) {
        await processPastedClearyLink(view, node, nodePos, richTextId, clearyLinkMark)
      }
    }
  })
}

export default processInsertedNodes
